import "./App.css";
import "./style/index.css";
import Header from "./components/layout/header";
import Container from "./components/layout/container";
import Footer from "./components/layout/footer";
import { generateRandom } from "./utils";
import Players from "./database/players.json";
import ReactGA from "react-ga4";

var initial = false;
var randId = -1;

ReactGA.initialize("G-05FJS0RZE7");
ReactGA.send({ hitType: "pageview", page: "/" });

function App() {
  const source = Players;
  if (initial === false) {
    randId = generateRandom(source.length);
  }
  initial = true;

  return (
    <div className="App">
      <Header />
      <div className="header">
        <div className="container">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://nbaquizzes.com/"
            style={{
              textDecoration: "none",
            }}
          >
            <h1>
              <img src="/logo.png" height={60} alt="logo" />
              <span class="sr">Poeltl</span>
            </h1>
          </a>
          <h2>Guess The MLB Player</h2>
        </div>
      </div>
      <Container randId={randId} source={source} />
      <Footer />
    </div>
  );
}

export default App;
